.userInfo {
    min-width: 200px;
    display: inline-block;
    .isUserAdmin {
        display: inline-block;
        margin-right: 5px;
    }
    .isMasterEditor {
        display: inline-block;
    }
    .localLangs {
        display: inline-block;
    }

    img {
        height: 20px;
        margin-right: 3px;
        border: 1px solid lightgrey;
    }
}