.authorPane {
    color: green;
    .errorText {
        color: red;
    }
    button {
        color: white;
        width: 100%;
        max-width: 200px;
        margin-top: 20px;
        border: 0;
    }
}

