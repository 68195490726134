.userRegistrations {
    margin-bottom: 20px;
    display: flex;
    &.hide {
        display: none;
    }
    &.alignRight {
        float: right;
    }
    .userReg {
        display: inline-block;
        background-color: #e5ecf9;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        vertical-align: middle;
        text-align: center;

        .initials {
            text-align: center;
            display: inline-block;
            margin-top: 10px;
            font-size: 16px;
            letter-spacing: 1px;
            font-family: Verdana;

            &.current {
                margin-top: 5px;
            }
        }

        border: 2px solid transparent;
        &.you {
            border: 2px solid #abb1bd;
        }
        margin: 3px;

        .you {
            font-size: 8px;
        }
    }

    .editingSymbols {
        text-align: center;
    }

    .warning {
        display: flex;
        background-color: #fff5f5;
        padding: 20px;
        border-radius: 4px;
        margin-right: 20px;
        .warningWrapper {
            display: flex;
            align-items: center;
            p {
                color: #bf2012;
            }
            svg {
                width: 16px;
                height: 16px;
            }
            span {
                font-weight: 500;
                margin-left: 5px;
            }
        }

    }
}