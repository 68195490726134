.languageSelect {
    .languageWrapper {
        width: 100%;
        max-width: 1000px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 20px;
        .flagsWrapper {
            width: 33.33%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            h4 {
                display: block;
                width: 100%;
                text-align: center;
            }
            ul {
                display: block;
                padding: 0;
                li {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

}
