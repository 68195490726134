.languageFlag {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    &:hover {
        background-color: #def7ff;
    }

    img {
        border: 1px solid #dadada;
        // width: 35px; // See UserEdit/styles.scss for dimensions
        margin-right: 10px;
    }
    p {
        font-size: 14px;
    }

}