
.organisationChoice {
    .org {
        vertical-align: middle;
        width: 200px;
        height: 200px;
        display: inline-block;
        border: 2px solid transparent;
        padding: 20px;
        margin: 20px;
        border-radius: 5px;
        img {
            width: 200px;
        }

        &:hover {
            border: 2px solid black;
            cursor: pointer;
        }
    }
}