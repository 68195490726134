.localChecklistList {
    padding-top: 100px;
    table {
        width: 100%;
        text-align: center;
    }

    .checklistTitle {
        display: flex;
        padding-bottom: 20px;
        img {
            width: 40px;
            padding-right: 10px;
        }
    }

    .listRowContainer {
        .listRowData:first-child  {
            text-align: center;
        }
        &.notPublished {
            .listRowData:first-child {
                background-color: #e2e2e2;
                border-top: 3px solid white;
            }
        }
        &.notAuthored {
            .listRowData:first-child {
                background-color: pink;
                border-top: 3px solid white;
            }
        }
        &.notLatest {
            .listRowData:first-child {
                background-color: lightsalmon;
                border-top: 3px solid white;
            }
        }
        &.isLatest {
            .listRowData:first-child {
                background-color: #beefbe;
                border-top: 3px solid white;
            }
        }
    }
}