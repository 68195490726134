.menuWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: #fafafa;
    .menuInnerwrapper {
        position: relative; // So that the user info panel and logout button appear underneath the user icon
        max-width: 1240px;
        margin: auto;

        h2 {
            padding: 20px;
            font-size: 18px;
            float: left;
        }

        &.isStaging {
            background-color: yellow;
        }
    }
    
    .hamburgerNav {
        display: none;
        @media screen and (max-width: 768px)  {
            display: block;
            position: absolute;
            top: 16px;
            right: 20px;
            z-index: 999;
            img {
                width: 30px;
                cursor: pointer;
            }
        }
    }

    .logoutMobileNav {
        display: none;
        @media screen and (max-width: 768px)  {
            display: flex;
            position: absolute;
            bottom: 20px;
            right: 20px;
            img {
                width: 20px;
                margin-right: 10px;
            }
        }
    }

    .menuItems {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        @media screen and (max-width: 768px) {
            display: flex;
            width: 0;
            overflow: hidden;
            display: flex;
            background-color: white;
            position: absolute;
            transition: ease width 0.3s;
            padding: 0;
            right: 0;
            top: 0;
            z-index: 990;
            justify-content: normal;
            flex-flow: column;
            white-space: nowrap;
            height: calc(100vh - 40px);
            padding-top: 40px;
            &.mobileNav {
                width: 250px;
                padding-left: 10px;
                box-shadow: 0px 0px 7px 0px #bbbbbb;
            }
        }
        .menuItem {
            margin-left: 18px;
            p, em {
                line-height: 64px;
                @media screen and (max-width: 786px) {
                    line-height: 40px;
                }
            }
            a {
                cursor: pointer;
                &:hover {
                    border-bottom: 2px solid black;
                    padding-bottom: 2px;
                }
            }
            a {
                color: #000000;
                text-decoration: none;
                &.active {
                    border-bottom: 2px solid black;
                    padding-bottom: 2px;
                }
            }
            
            &.user {
                width: 22px;
                line-height: 64px;
                margin-top: 5px;
                cursor: pointer;
                .mobileNavUser {
                    display: none;
                }
                @media screen and (max-width: 768px) {
                    display: flex;
                    pointer-events: none;
                    img {
                        width: 20px;
                        margin-right: 5px;
                    }
                    .mobileNavUser {
                        display: flex;
                    }
                }

            }
        }

        .divider {
            border-left: 1px solid #d6d6d6;
            height: 30px;
            top: 18px;
            position: relative;
            right: -10px;
            margin: 0 7px;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }

    .profile {
        position: absolute;
        right: 20px;
        top: 70px;
        /* height: 100px; */
        min-width: 200px;
        background: #fafafa;
        border-radius: 4px;
        padding: 16px 20px 35px;
        
        box-shadow: 0 0 4px 0px #c7c7c7;
        z-index: 100;
        
        .profileInner {
            display: flex;
            padding-bottom: 20px;

            .userInfo {
                .email {
                    padding-top: 10px;
                    padding-bottom: 15px;
                }
            }
        }
        
        .logout {
            position: absolute;
            bottom: 8px;
            right: 16px;
            display: flex;
            cursor: pointer;
            img {
                width: 16px;
                margin-right: 4px;
            }
        }
    }

    .overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
    }

    .small {
        font-size: 10px;
    }

    .hyperlink {
        display: inline;
        &:hover {
            cursor: pointer;
            border-bottom: 2px solid black;
            padding-bottom: 2px;
        }
        &.active {
            border-bottom: 2px solid black;
            padding-bottom: 2px;
        }
    }
}