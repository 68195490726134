.userEdit {
    padding-top: 30px;

    .userInfoHeader {
        border-bottom: 1px solid gainsboro;
        margin-bottom: 20px;
        padding-bottom: 10px;
    }

    .userEditInfo {
        display: flex;
        width: 200px;
        margin-bottom: 5px;
        label {
            white-space: nowrap;
            width: 130px;
        }
        span {
            width: 100%;
            white-space: nowrap;
            text-align: left;
        }
    }



    .languageFlags {
        margin: 0px;
        margin-bottom: 30px;
        padding: 0px;

        li {
            list-style: none;
            display: inline-block;
            margin: 5px 10px 5px 0;


            .languageFlag {
                background-color: #eee;
                img {
                    height: 20px;
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                }
            }

            &.selectedLanguage {
                .languageFlag {
                    background-color: lightgreen;
                    img {
                        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                        filter: grayscale(0%);
                    }
                }
            }
        }

    }

    .permissionWrapper {
        padding-top: 55px;
        h2 {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid gainsboro;
        }

        &.org {
            border: 1px solid black;
            border-radius: 5px;
            padding: 10px;
            margin: 10px;
            margin-left: 50px;
        }
    }

    .continentsEdit {
        clear: both;
        padding-top: 20px;
        max-width: 900px;
    }

    .permissionsEdit {
        padding-top: 60px;

        h4 {
            padding-bottom: 10px;
        }

        ul {
            margin: 0;
        }
    }

    .permission {
        clear: both;

        h4 {
            border-bottom: 1px solid gainsboro;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }

        &.toggle {
            display: flex;
            margin-right: 20px;
            padding-top: 10px;
            width: 100px;
            display: inline-block;
            p {
                padding-bottom: 10px;
                white-space: nowrap;
            }
        }
    }
    
}