.loginForm {
    background-color: #eee;
    width: 300px;
    margin: 20px auto;
    text-align: center;
    padding: 20px 80px;
    @media screen and (max-width: 768px) {
        padding: 20px;
        width: auto;
    }
    
    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style: none;
            margin-bottom: 10px;

            button {
                width: 100%;
                padding: 10px 0;
                background-color: white;
                border: 1px solid #c1c1c1;
                border-radius: 4px;
                cursor: pointer;
                outline: none;
            }
        }
    }

    .loginError {
        color: red;
    }
}

