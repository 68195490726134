.downloads {
    padding-top: 50px;
    display: flex;
    .header {
        padding-top: 0;
        padding-bottom: 20px;
        font-size: 26px;
        text-align: center;
    }
    .flag {
        max-height: 30px;
    }
    table {
        td, th {
            border: 1px solid grey;
            padding: 5px;
        }
        border-collapse: collapse;

        td.notAuthored {
            color: lightgray;
        }
        td.notTranslated {
            color: lightgray;
        }
        td.latestVersion {
            color: black;
            background-color: lightgreen;
        }
        td.olderVersion {
            color: black;
            background-color: lightsalmon;
        }
    }

    .listContainer {
        width: 50%;
    }
    .listHeaders {
        //display: flex;

        .listHeader {
            display: flex;
            align-items: center;
            //width: 20%;
            border-right: 1px solid #e8ecee;
            //border-top: 1px solid #e8ecee;
            border-bottom: 1px solid #e8ecee;
            padding: 8px 10px;
            height: 50px;
            &:first-child {
                border-top: 1px solid #e8ecee;
            }
            img {
                width: 32px;
                margin-right: 5px;
                padding: 5px
            }
        }
    }
    
    .listRowData {
        display: flex;
        align-items: center;
        position: relative;
        //width: 20%;
        border-right: 1px solid #e8ecee;
        border-bottom: 1px solid #e8ecee;
        padding: 8px 10px;
        height: 50px;
        .dataWrapper {
            position: relative;
            //top: 50%;
            //transform: translateY(-50%);
            p {
                font-weight: 500;
            }
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 8px 0;
            .dataWrapper {
                transform: none;
            }
        }
    }
    .listDataWrapper {
        width: 70%;
    }

    .listRowContainer {
        .listRowData {
            &:first-child {
                border-top: 1px solid #e8ecee;
            }
        }
    }

    .listDataWrapper .listRowContainer {
        //display: flex;
        @media screen and (max-width: 768px) {
            flex-flow: column;
            margin-bottom: 10px;
            border-bottom: 2px solid #cccccc;
            padding-bottom: 30px;
        }
    }
}

.app .innerAppWrapper .downloadSpecific {
    padding-top: 50px;
    .header {
        padding-top: 0;
        padding-bottom: 20px;
        font-size: 26px;
        text-align: center;
    }
    .rowWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: center;
        .innerRowWrapper {
            display: flex;
            border-bottom: 1px solid #e8ecee;
            border-top: 1px solid #e8ecee;
            padding: 20px 0;
            align-items: center;
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
        .textWrapper {
            padding: 0 40px;
            @media screen and (max-width: 768px) {
                padding: 20px 15px;
                text-align: left;
            }
            &:nth-child(2) {
                border-left: 1px solid #e8ecee;
                border-right: 1px solid #e8ecee;
                @media screen and (max-width: 768px) {
                    border: 0;
                }
            }
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    display: flex;
                    padding-bottom: 5px;
                    a {
                        text-decoration: none;
                        font-size: 15px;
                    }
                    img {
                        width: 22px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }


    p.warning {
        color: orange;
    }
}