.users {
    padding-top: 50px;
    @media screen and (max-width: 786px) {
        padding-top: 0px;
    }
    .listHeaders {
        display: flex;

        .listHeader {
            width: 20%;
            border-right: 1px solid #e8ecee;
            border-top: 1px solid #e8ecee;
            border-bottom: 1px solid #e8ecee;
            padding: 8px 10px;

            &.initials {
                width: 10%;
            }
            &.email {
                width: 30%;
            }
            &.edit {
                width: 10%;
            }
        }

    }
    
    .listRowData {
        position: relative;
        width: 20%;
        border-right: 1px solid #e8ecee;
        border-bottom: 1px solid #e8ecee;
        padding: 8px 10px;
        &.initials {
            width: 10%;
        }
        &.email {
            width: 30%;
        }
        &.edit {
            width: 10%;
        }
        .dataWrapper {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            word-break: break-word;
            &.permissions {
                p {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 8px 0;
            .dataWrapper {
                transform: none;
            }
        }
    }

    .listDataWrapper .listRowContainer {
        display: flex;
        @media screen and (max-width: 768px) {
            flex-flow: column;
            margin-bottom: 10px;
            border-bottom: 2px solid #cccccc;
            padding-bottom: 30px;
        }
    }

    .checkListUsersEdit {
        .userRegistrations {
            .editingSymbols {
                text-align: left;
                padding-top: 10px;
                > p {
                    font-size: 15px;
                }
                .userReg {
                    width: 30px;
                    height: 30px;
                    .initials {
                        font-size: 14px;
                        margin-top: 6px;
                    }
                }
            }
        }
    }

    .showPermissions {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        min-height: 299px;
        background: #fafafa;
        border-radius: 4px;
        padding: 16px 20px 35px;
        box-shadow: 0 0 4px 0px #c7c7c7;
        z-index: 100;
        &.show {
            display: block;
        }
        &.hide {
            display: none;
        }
        .close {
            width: 17px;
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }
        .header {
            font-size: 18px;
            text-align: center;
            border-bottom: 1px solid #e8ecee;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
    }

    .permissions {
        .editorHeader {
            display: block;
            padding-bottom: 10px;
        }
        > div:not(:last-child) {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #e8ecee;
        }
        img {
            height: 28px;
            margin-right: 10px;
        }
    }
    
    .permissionsOverlay {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;
    }

    .addUserRow {
        margin-top: 20px;
        p {
            margin-bottom: 10px;
        }
        .wrapper {
            display: flex;
            align-items: center;
            max-width: 600px;

            button {
                max-width: 150px;
                margin-left: 15px;
            }
        }
    }
}