.configPane {
    padding-left: 10px;

    .contextHeading {
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    
        display: inline-block;
        margin: 6px 0px;
        
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #e8ecee;

        td, th {
            border: 1px solid #e8ecee;
            padding: 8px;
        }
    }

    @media screen and (max-width: 768px) {
        .overrideText {
            input {
                min-width: 200px;
            }
        }
    }
}

