.permissionRequired {
    border: 1px solid red;
    border-radius: 5px;
    background-color: pink;
    padding: 10px;

    h3 {
        margin-top: 0px;
    }
    p {
        margin: 4px;
    }
}