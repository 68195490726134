
.checklistEdit {
    padding-top: 100px;

    .checklistTitle {
        display: flex;
        padding-bottom: 5px;
    }

    .todo {
        color: red;
        &.none {
            color: green;
        }
    }

    .navWrapper {
        width: 100%;
        > nav {
            width: 100%;
        }
    }
}