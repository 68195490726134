.variableOverride {

    width: 100%;

    td {
        width: 50%;
    }
    td.idCol {
        width: 100px;
    }
    
    td.defaultText {
        .defaultTextWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 5px;
            p {
                width: 80%;
                margin-right: auto;
            }
            &.overridden {
                color: grey;
            }
            & > div.a {
                width: 20%;
                margin-left: 20px;
                button {
                    padding: 7px 24px;
                    text-transform: capitalize;
                }
            }

        }

    }

    td.overrideText {
        &.editing {
            padding: 10px;
        }
    }

    button {
        float: right;
    }

    .custom-save-button, .custom-cancel-button {
        position: relative;
        background: none;
        border: 0;
        line-height: 0;
        outline: none;
        cursor: pointer;
        width: 25px;
        padding: 16px;
        
        &:before {
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            transition: ease 0.4s all;
        }
    }
    .custom-save-button {
        &:before {
            background-image: url("../../assets/check.svg");
            background-size: 17px;
            width: 17px;
            height: 17px;
        }
    }
    .custom-cancel-button {
        &:before {
            background-image: url("../../assets/cancel.svg");
            background-size: 15px;
            width: 15px;
            height: 15px;
        }
    }

    .overrideWrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        padding: 2px 5px;
        background-color: #d8ffd8;
        padding: 10px;
        margin: -10px;
        span {
            width: 80%;
        }
        & > div {
            width: 20%;
            margin-left: 20px;
            button {
                padding: 7px 24px;
                text-transform: capitalize;
            }
        }
    }
    
}
