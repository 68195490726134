.addChecklist {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    padding-bottom: 20px;
    border-radius: 4px;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
        padding: 20px 0;
        display: block;
    }

    .errorText {
        display: block;
        width: 100%;
        padding-top: 5px;
        p {
            color: #e24e4e;
        }
    }

    .addChecklistWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;

        .inputWrapper {
            display: flex;
            flex-wrap: wrap;
            input {
                display: block;
                width: 100%;
            }
            input {
                padding-left: 10px;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                padding-bottom: 10px;
                #checklistIdent {
                    margin-top: 0 !important;
                }
            }
        }

        .dataInput {
            margin: 0;
            margin-right: 14px;
        }
    }

    label {
        padding: 10px 0;
    }
    .dataInput {
        height: 40px;
        padding: 2px 10px 2px 0px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        outline: none;
        &.inputError {
            border-color: #bf1f12;
        }

        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            margin: 15px auto !important;
        }
    }

    button {
        &:disabled {
            background-color: #fafafa !important;
            color: grey !important;
            border: 1px solid #e0e0e0;
        }
        border: 0;
        text-transform: none;
        background-color: #284e80;
        color: white;
        height: 40px;
        width: 140px;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        font-size: 16px;
        transition: ease background-color 0.4s;
        &:hover {
            background-color: #000000;
        }

        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
        }
    }

}

