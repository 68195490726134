body, html {
    margin: 0;
}
h1, h2, p {
    margin: 0;
    padding: 0;
}

.app {
    font-family: arial;
    font-size: 16px;
    box-sizing: border-box;
    padding-top: 64px;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 70px;

    @media screen and (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .innerAppWrapper {
        width: 100%;
        max-width: 1200px;
        margin: auto;
    }
    
    .backBtnWrapper {
        position: absolute;
        text-decoration: none;
        top: 85px;
        .backBtn {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            background: #ececec;
            padding: 10px 25px;
            border-radius: 4px;
            max-width: 60px;
            transition: background ease 0.2s;
            &:hover {
                background: #fafafa;
            }
            img {
                width: 10px;
            }
            p {
                font-size: 17px;
                margin-left: 4px;
            }
        }
    }

}
