.listData {
    .innerWrapper {
        display: table;
        width: 100%;

        .dataRow {
            display: flex;
            outline: 0;

            &:hover {
                background-color: #f6f8f9;
            }
            &.selected {
                border: 1px solid green;
            }
        }

        .dataCell {
            display: flex;
            border-right: 1px solid #e8ecee;
            border-top: 1px solid #e8ecee;
            outline: 0;

            &.dataCellHeaderProduct, &.dataCellHeaderType {
                height: 40px;

                font-weight: 600;
                padding: 0 11px;

                p {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .dataCellHeaderProduct {
            &.notes {
                @media screen and (max-width: 786px) {
                    width: 100% !important;
                }
            }
        }

        .dataCellHeaderProduct, .dataCellValue {
            width: 100%;
            min-height: 20px;
            @media screen and (max-width: 768px) {
                width: 65%;
            }
        }

        .dataCellHeaderType, .dataCellType {
            width: 30%;
            @media screen and (max-width: 768px) {
                width: 35%;
            }
        }

        .dataCellHeaderType {
            width: calc(30% + 50px);
            @media screen and (max-width: 768px) {
                width: calc(35% + 50px);
            }
        }

        .dataCellMenu {
            width: 50px;
        }

    }

    .addRowButton {
            border-top: 1px solid #e8ecee;
            padding: 10px 0;
        .addWrapper {
            display: flex;
            cursor: pointer;
            width: 110px;

            img {
                width: 20px;
                margin-right: 10px;
            }
        }

    }

}