.checklistList {
    padding-top: 50px;
    @media screen and (max-width: 786px) {
        padding-top: 0px;
    }
    .listHeaders {
        display: flex;

        .listHeader {
            width: 20%;
            border-right: 1px solid #e8ecee;
            border-top: 1px solid #e8ecee;
            border-bottom: 1px solid #e8ecee;
            padding: 8px 10px;
        }
    }
    
    .listRowData {
        position: relative;
        width: 20%;
        border-right: 1px solid #e8ecee;
        border-bottom: 1px solid #e8ecee;
        padding: 8px 10px;
        .dataWrapper {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            word-break: break-word;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 8px 0;
            .dataWrapper {
                transform: none;
            }
        }
    }

    .listDataWrapper .listRowContainer {
        display: flex;
        @media screen and (max-width: 768px) {
            flex-flow: column;
            margin-bottom: 10px;
            border-bottom: 2px solid #cccccc;
            padding-bottom: 30px;
        }
    }

    .checkListUsersEdit {
        .userRegistrations {
            .editingSymbols {
                text-align: left;
                padding-top: 10px;
                > p {
                    font-size: 15px;
                }
                .userReg {
                    width: 30px;
                    height: 30px;
                    .initials {
                        font-size: 14px;
                        margin-top: 6px;
                    }
                }
            }
        }
    }
}