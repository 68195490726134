.dataCellValue, .dataCellType {
    padding: 8px 10px;
    border: 1px solid transparent;
    .valueText, .valueType {
        cursor: text;
        display: flex;
        align-items: center;
        font-family: "Volvo Novum", Arial, sans-serif;
    }

    &.notes {
        @media screen and (max-width: 768px) {
            width: 100% !important;
        }
    }

    &.selected {
        border: 1px solid #339033 !important;
    }

    button[editext="edit-button"] {
        display: none;
    }

    div[editext="edit-container"] > div {
        align-items: baseline;
    }

    .styles_Editext__main_container__2Y-IL {
        width: 100%;
        flex-direction: row;
        textarea {
            border: 0;
            font-family: "Volvo Novum", Arial, sans-serif;
            font-size: 16px;
            width: 100%;
            resize: none;
            color: #141414;
            padding: 10px;
        }
        > div {
            width: 100%;
        }
    }

    .selectWrapper {
        width: 165px;
        color: #141414 !important;
        font-family: "Volvo Novum", Arial, sans-serif !important;

        > div > div {
            border: 0;
        }    
    }

    .custom-save-button, .custom-cancel-button {
        position: relative;
        background: none;
        border: 0;
        line-height: 0;
        outline: none;
        cursor: pointer;
        width: 25px;
        padding: 16px;
        
        &:before {
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            transition: ease 0.4s all;
        }
    }
    .custom-save-button {
        &:before {
            background-image: url("../../assets/check.svg");
            background-size: 17px;
            width: 17px;
            height: 17px;
        }
    }
    .custom-cancel-button {
        &:before {
            background-image: url("../../assets/cancel.svg");
            background-size: 15px;
            width: 15px;
            height: 15px;
        }
    }

}

.dataCellValue, .dataCellType {
    &.editing {
        background-color: #f6f8f9;
    }
}


.dataCellMenu {
    position: relative;
    .menuIcon {
        width: 20px;
        margin: auto;
        cursor: pointer;
    }
    
    .editMenu {
        background: #fff;
        top: 22px;
        right: 10px;
        position: absolute;
        display: none;
        box-shadow: 0 0 6px 0px #c7c7c7;
        z-index: 99;
        
        &.open {
            display: block;
        }

        hr {
            margin: 0 10px;
            border: 1px solid #e4e3e3;
            border-top: 0;
        }

        .editMenuWrapper {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            .itemWrapper {
                .deleteItem, .editItem, .insertRow {
                    white-space: nowrap;
                    display: flex;
                    cursor: pointer;
                    transition: ease all 0.2s;
                    padding-right: 25px;
                    padding-left: 12px;
                    &:hover {
                        background-color: #007bcd;
                        color: #fff;
                    }
                    img {
                        width: 16px;
                        padding-right: 5px;
                    }
                }
                .deleteItem {
                    padding-top: 12px;
                    padding-bottom: 12px;

                }
                .editItem {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    img {
                        width: 17px;
                    }
                }
                .insertRow {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    img {
                        width: 19px;
                    }
                }
            }

            hr {
                margin: 0 10px;
            }
        }
    }

    .overlay {
        z-index: 98;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}


.deletePrompt {
    position: relative;
    width: 500px;
    background-color: #fff;
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%,-60%);
    z-index: 999;
    text-align: center;
    padding: 20px;
    pointer-events: all;
    text-align: center;
    padding-bottom: 25px;
    border-radius: 4px;
    @media screen and (max-width: 768px) {
        width: 90%;
        margin: 0;
        padding: 20px 0;
    }
    .header {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 28px;
        color: #626262;
    }

    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        margin: -15px;
        padding: 15px;
        cursor: pointer;
        img {
            width: 15px;
            height: 15px;
        }
    }

    button {
        margin: 8px 10px 0 10px;
        padding: 8px 40px;
        cursor: pointer;
        transition: background-color .3s ease;
        border: 0;
        color: #fff;
        box-shadow: none;
        border-radius: 4px;
        outline: none;
        p {
            color: #fff;
        }
        &.noButton {
            background-color: #e1e5e8;
            margin-left: 8px;
            transition: background-color 0.4s ease;
            &:hover {
                background-color: #eef2f5;
            }
            p {
                color: #3a3c3e;
            }
        }
        &.yesButton {
            background-color: #d65446;  
            margin-right: 8px;
            transition: background-color 0.4s ease;
            &:hover {
                background-color: #e66355;
            }
        }
    }
}

.promptOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 102;
    top: 0;
    left: 0;
    transition: ease 0.3s opacity;
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
    &.show {
        opacity: 1;
        pointer-events: all;
    }
}
