.errorsBar {
    width: 100%;
    position: fixed;
    top: 30px;
    left: 0px;
    
    text-align: left;
    z-index: 1;

    .error {
        padding: 5px;
        margin: 5px;
        border-radius: 5px;
        border: 1px solid #bf2012;
        background-color: #fff5f5;
        color: #bf2012;
        min-height: 30px;
        line-height: 30px;
        max-width: 960px;
        margin: auto;
        text-align: center;
        div.close {
            float: right;
            span {
                cursor: pointer;
            }
        }
    }
}