.disconnectedBar {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 20px;
    background-color: red;
    text-align: center;
    color: white;
    z-index: 2;

    &.connected {
        background-color: green;
    }
}