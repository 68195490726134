.translationList {

    .dataCell, .dataCellMaster {
        padding: 8px 10px;
        border: 1px solid transparent;
        cursor: text;
        display: flex;
        align-items: center;
        font-family: "Volvo Novum", Arial, sans-serif;
        font-weight: 300;
        color: rgb(20, 20, 20);
    }

    .usefulButtons {
        display: inline-block;
        text-align: right;
        width: 100%;
        > div {
            width: auto;
            display: inline-block;
        }
        button {
            display: inline-block;
            width: auto;

            padding: 5px 15px;
            font-size: small;
            font-variant: normal;
            text-transform: none;
            margin: 5px;
        }
        
    }
    .innerWrapper {
        display: table;
        width: 100%;

        .dataRow {
            display: flex;
            outline: 0;

            &.missing {
                background-color: pink;
            }
            
            &:hover {
                background-color: #f6f8f9;
            }
            
            
        }

        .dataCell {
            display: flex;
            border-right: 1px solid #e8ecee;
            border-top: 1px solid #e8ecee;
            outline: 0;

            &.selected {
                border: 1px solid green;
            }

            &.dataCellHeaderMaster, &.dataCellHeaderLocal {
                height: 40px;
                font-weight: 600;
                padding: 0 11px;

                p {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .dataCellHeaderMaster, .dataCellMaster {
            width: 100%
        }

        .dataCellHeaderLocal, .dataCellLocal {
            width: 100%;
        }

        .dataCellHeaderLocal {
            width: 100%
        }

        .dataCellMenu {
            width: 50px;
        }

    }

    .addRowButton {
        border-top: 1px solid #e8ecee;
        padding: 10px 0;
        .addWrapper {
            display: flex;
            cursor: pointer;
            width: 110px;

            img {
                width: 20px;
                margin-right: 10px;
            }
        }

    }

        .custom-save-button, .custom-cancel-button {
            position: relative;
            background: none;
            border: 0;
            line-height: 0;
            outline: none;
            cursor: pointer;
            width: 25px;
            padding: 16px;
        
        &:before {
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            transition: ease 0.4s all;
        }
    }
    .custom-save-button {
        &:before {
            background-image: url("../../assets/check.svg");
            background-size: 17px;
            width: 17px;
            height: 17px;
        }
    }
    .custom-cancel-button {
        &:before {
            background-image: url("../../assets/cancel.svg");
            background-size: 15px;
            width: 15px;
            height: 15px;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .buttonWrapper {
        margin: 0 10px;
        button {
            height: 40px;
            line-height: 40px;
            padding: 0 15px;
        }
        &.edit {
            margin-left: auto;
            padding-left: 10px;
            button {
                max-width: 100px;
                height: 40px;
                line-height: 40px;
                padding: 0;
            }
        }
    }

    .styles_Editext__main_container__2Y-IL {
        width: 94%;
    }

}