
.checklistEdit {
    padding-top: 100px;

    .checklistTitle {
        display: flex;
        padding-bottom: 5px;
    }

    .userRegWrapper {
        width: 100%;
        height: 100%;
        position: relative;

        .userRegistrations {
            position: absolute;
            right: 0;

            .editingSymbols {
                position: relative;
                top: -10px;
            }
        }
    }

    .button {
        padding: 7px;
        background-color: #aaa;
        border-radius: 4px;
        margin: 5px;
        color: black;
        &:hover {
            background-color: blue;
            color: white;
        }
        display: inline-block;
        float: right;
        text-decoration: none;
    }
}